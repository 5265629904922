.tab {
    padding-left: 14em;
}
.h1 {
    text-align: left;
    font-family: 'roboto slab';
    font-weight: 100;
    font-size: xx-large;
    padding-bottom: 0.1em;
}
:root {
    --tucr-lime:#c8f124;
    --tucr-red:#d42232;
    --tucr-green:#7deb30;
    --tucr-darkgreen:#066f41;
    --tucr-dark: #111410 !important; /*#232e25*/;
    --bs-gray-300: #dee2e6;
}
.box {
    margin:15em auto;
}
body {
    background-image: radial-gradient( #334230, var(--tucr-dark) 70%);
}