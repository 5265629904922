.show-counter {
    font-family: 'Roboto';
    margin: 0 auto;
    width: max-content;
    font-weight: bold;
}
span {
    font-size: xx-large;
}
:root {
    --tucr-lime:#c8f124;
    --tucr-red:#d42232;
    --tucr-green:#7deb30;
    --tucr-darkgreen:#066f41;
    --tucr-dark: #111410 !important; /*#232e25*/;
    --bs-gray-300: #dee2e6;
}
.countdown{
    color: var(--tucr-lime);
    padding: 10px;
}